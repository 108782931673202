<template>
  <div>
    <Toolbar class="p-mb-4">
      <template #left>
        <Button
          label="اضافه جديد"
          icon="pi pi-plus"
          v-if="$checkRoles('adminsAdd')"
          class="p-ml-2 p-button-success"
          @click="$router.push('admins/add')"
        />
        <Button
          label="حذف"
          icon="pi pi-trash"
          @click="deleteAll"
          v-if="$checkRoles('adminsDelete')"
          :disabled="selectedItems.length == 0"
          class="p-button-danger"
        />
      </template>

      <template #right>
        <!-- <Button icon="pi pi-cog" class="p-button-basic p-ml-2" /> -->
        <Button
          icon="pi pi-download"
          class="p-button-help" v-tooltip="'تحميل'"
          @click="exportCSV($event)"
        />
        <!-- <Button icon="pi pi-search" class="p-ml-2" />
        <Button icon="pi pi-times" class="p-button-danger" /> -->
      </template>
    </Toolbar>

    <DataTable
      ref="dt"
      dataKey="id"
      :value="list"
      :paginator="true"
      class="
      p-datatable-responsive-demo p-datatable-customers"
      :rows="100"
      :rowHover="true"
      :selection.sync="selectedItems"
      :loading="loading"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[10, 25, 50, 100]"
      currentPageReportTemplate="عرض {first} الى {last} من {totalRecords} مدخلات"
    >
      <template #header>
        <div class="table-header">
          قائمه المديرين

          <div style="text-align:left">
            <MultiSelect
              :value="selectedColumns"
              :options="columns"
              optionLabel="header"
              @input="onToggle"
              placeholder="اختيار الاعمده"
              style="width: 20em"
            />
          </div>
        </div>
      </template>
      <template #empty>
        لا يوجد بيانات
      </template>
      <template #loading>
        يتم تحميل البيانات. يرجي الانتظار...
      </template>
      <Column selectionMode="multiple" field="id" headerStyle="width: 3em" />
      <Column
        v-for="(col, index) of selectedColumns"
        :key="col.field + '_' + index"
        :field="col.field"
        :header="col.header"
        :sortable="true"
      />

      <Column field="id" bodyStyle="text-align: center; overflow: visible">
        <template #body="slotProps">
          <Button
            type="button"
            @click="$router.push('admins/roles/' + slotProps.data.id)"
            icon="pi pi-key"
            v-if="slotProps.data.id != 1"
            class="p-button-info p-ml-2  p-button-rounded"  v-tooltip="'الصلاحيات'"
          ></Button>
          <Button
            type="button"
            v-if="$checkRoles('adminsEdit')"
            @click="$router.push('admins/edit/' + slotProps.data.id)"
            icon="pi pi-pencil"
            class="p-button-success p-ml-2  p-button-rounded"  v-tooltip="'تعديل'"
          ></Button>
          <Button
            type="button"
            icon="pi pi-trash"
            v-if="slotProps.data.id != 1 && $checkRoles('adminsDelete')"
            @click="deleteItem(slotProps.data.id)"
            class="p-button-warning  p-button-rounded" v-tooltip="'حذف'"
          ></Button>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      selectedItems: [],
      selectedColumns: [],
      columns: [
        {
          field: 'userName',
          header: 'اسم المستخدم',
        },
        {
          field: 'email',
          header: 'البريد الألكتروني',
        },
        {
          field: 'phone',
          header: 'الهاتف',
        },
      ],
      loading: true,
    };
  },
  methods: {
    getData() {
      this.$http.get(`users`).then(
        (response) => {
          this.loading = false;
          this.list = response.data;
        },
        (err) => {
          this.loading = false;
          this.$toast.add({
            severity: 'error',
            summary: 'هناك خطأ',
            detail: err.response.data.message,
            life: 3000,
          });
        },
      );
    },
    onToggle(value) {
      this.selectedColumns = this.columns.filter((col) => value.includes(col));
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    deleteAll() {
      this.$confirm.require({
        message: 'هل انتا متاكد انك تريد حذف السجلات',
        header: 'تأكيد الحذف',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          const index = this.selectedItems.indexOf(1);
          if (index > -1) {
            this.selectedItems.splice(index, 1);
          }

          this.$http
            .post(`users/deleteList`, {
              idList: this.selectedItems,
            })
            .then(
              () => {
                this.getData();
                this.$toast.add({
                  severity: 'error',
                  summary: 'تم بنجاح',
                  detail: 'تم الحذف بنجاح',
                  life: 3000,
                });
              },
              (err) => {
                this.$toast.add({
                  severity: 'error',
                  summary: 'هناك خطأ',
                  detail: err.response.data.message,
                  life: 3000,
                });
              },
            );
        },
      });
    },
    deleteItem(id) {
      this.$confirm.require({
        message: 'هل انتا متاكد انك تريد حذف السجلات',
        header: 'تأكيد الحذف',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.$http.delete(`users/${id}`).then(
            () => {
              this.getData();
              this.$toast.add({
                severity: 'error',
                summary: 'تم بنجاح',
                detail: 'تم الحذف بنجاح',
                life: 3000,
              });
            },
            (err) => {
              this.$toast.add({
                severity: 'error',
                summary: 'هناك خطأ',
                detail: err.response.data.message,
                life: 3000,
              });
            },
          );
        },
      });
    },
  },
  created() {
    this.getData();
    this.selectedColumns = this.columns;
  },
};
</script>

<style></style>
